import React from 'react'
import { Auth } from 'aws-amplify'

class UploadRequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sourceFileserver: 'storage',
      comment: '',
      sourceDirectory: '',
      uploadedNewRequest: false,
      apiResponseStatus: 0,
      alertClassString: '',
      makingPostRequest: false,
      buttonText: 'Submit request'
    };

    this.addNewRequest = this.addNewRequest.bind(this);
    this.updateAlertClass = this.updateAlertClass.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  async addNewRequest() {
    await this.setState({
      makingPostRequest: true,
      buttonText: 'Loading...'
    });

    const { sourceFileserver, comment, sourceDirectory } = this.state;

    const newRequest = {
      sourceFileserver: sourceFileserver,
      comment: comment,
      sourceDirectory: sourceDirectory,
      targetArchiveid: this.props.archiveid,
      targetArchiveTitle: this.props.title
    };

    const newRequestString = JSON.stringify(newRequest);

    Auth.currentSession()
      .then(async data => {
        const res = await fetch(`${process.env.GATSBY_API_URL}/requests`,
          { method: 'POST',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            }),
            body: newRequestString });

        let message = await res.json();

        this.setState({
          uploadedNewRequest: true,
          apiResponseStatus: res.status,
          apiResponseMessage: message.message
        });

        this.updateAlertClass();
      })
      .catch(err => console.log(err));
  }

  updateAlertClass() {
    const { apiResponseStatus } = this.state;

    if (apiResponseStatus === 200) {
      var alertClassString = 'c-alert c-alert--success'
    } else if (apiResponseStatus === 409) {
      alertClassString = 'c-alert c-alert--warning c-alert'
    } else {
      alertClassString = 'c-alert c-alert--danger c-alert'
    }

    this.setState({
      alertClassString: alertClassString,
      makingPostRequest: false,
      buttonText: 'Submit request'
    });
  }

  submitRequest() {
    this.setState({
      requestSubmitted: !this.state.requestSubmitted
    });
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return(
      <React.Fragment>
        <br />
        { this.state.uploadedNewRequest &&
          <div class={this.state.alertClassString} role='alert'>
            <div class='c-alert__content'>
              <strong>{this.state.apiResponseMessage}</strong>
            </div>
          </div>
        }

        <h3>New upload request</h3>
        <br />
        <label class='c-form__label' for='sourceFileserver'>Source fileserver</label>
        <div class='c-form__input c-form__input--select'>
          <select
            class='c-form__select'
            id='sourceFileserver'
            name='sourceFileserver'
            value={this.state.sourceFileserver}
            onChange={this.handleInputChange}
          >
            <option value='storage'>storage.york.ac.uk</option>
            <option value='cryoemstg'>cryoemstg.york.ac.uk</option>
            <option value='bioldata'>bioldata.york.ac.uk</option>
            <option value='viking'>viking.york.ac.uk</option>
            <option value='biolarfs3'>biolarfs3.york.ac.uk</option>
          </select>
        </div>

        <br />

        <div class='c-form__element'>
          <label class='c-form__label' for='sourceDirectory'>Source directory</label>
          <input
            class='c-form__input c-form__input--text'
            id='sourceDirectory'
            type='text'
            name='sourceDirectory'
            value={this.state.sourceDirectory}
            onChange={this.handleInputChange}
            placeholder='e.g. /'
          />
        </div>

        <br />
        <div class='c-form__element'>
          <label class='c-form__label'>Comment</label>
          <textarea
            class='c-form__input c-form__input--textarea'
            type='text'
            name='comment'
            value={this.state.comment}
            onChange={this.handleInputChange}
            placeholder='Anything else you need to let us know about this upload request'
          />
        </div>
        <br />

        <div class='o-grid__box o-grid__box--full'>
          <button
            class='c-btn c-btn--success c-btn--medium'
            onClick={this.addNewRequest}
            disabled={this.state.makingPostRequest}
          >
            {this.state.buttonText}
          </button>
        </div>
      </React.Fragment>);
  }
}

export default UploadRequestForm
